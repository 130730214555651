<template>
  <base-layout :withHeaderStructure="true">
    <template v-slot:header>
      <nav v-if='isDetailPage'>
        <router-link to="/news" class="flex items-center text-sm leading-5 font-medium text-gray-400 hover:text-gray-200 focus:outline-none focus:underline transition duration-150 ease-in-out">
          <svg class="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd"/>
          </svg>
          Back to news
        </router-link>
      </nav>
      <h1 class="font-bold leading-7 text-gray-900 text-2xl sm:text-3xl leading-9 sm:truncate tracking-tight">News
        <span v-if="categoryTitle && !isDetailPage"> - {{categoryTitle}}</span>
        <span v-if="isDetailPage"> - {{getCategoryNameByIdLong(newsPostEnriched.catId)}}</span>
      </h1>
      <h3 v-if='isDetailPage' class="leading-7 text-gray-900 text-lg leading-9 sm:truncate tracking-tight">{{newsPostEnriched.title}}</h3>
    </template>
    <template v-slot:main>
      <div>
        <div class="grid grid-cols-12 gap-6">
          <div class="col-span-12 lg:col-span-9 text-sm">
            <div v-if='!isDetailPage && firstNewsItem'>
              <div class='w-full'>
                <div class='bg-white shadow overflow-hidden sm:rounded-lg py-8 px-6 mb-8'>
                  <div v-if='firstNewsItem.isPinned==1'>
                    <img class="float-right object-fill h-5" src="/img/pin.png" title="Pinned News Article">
                  </div>
                  <a href='javascript:void(0)' @click="navToNews(firstNewsItem.id)" class="text-lg font-bold leading-5 text-blue-700 hover:text-blue-700 mt-4 mb-2 hover:underline"><h1>{{firstNewsItem.title}}</h1></a>
                  <div class='text-xs text-grey-500'>Posted by {{firstNewsItem.by}} on {{new Date(firstNewsItem.date).toLocaleDateString('en-US', {month: 'long', year: 'numeric', day: 'numeric'})}}</div>
                  <div v-html="firstNewsItem.bodyHtml" class='news-body mt-8 leading-5'></div>
                  <a v-if='firstNewsItem.doReadMore' href='javascript:void(0)' @click="navToNews(firstNewsItem.id)" class='block mt-2 text-blue-700 hover:text-blue-700 hover:underline'>Read More</a>
                  <div class='grid grid-cols-2 w-full border-t mt-4 pt-4'>
                    <div>
                      <router-link :to="`/news/${getCategoryNameById(firstNewsItem.catId)}`" class='text-blue-700 hover:text-blue-700 truncate hover:underline'>More Related News</router-link>
                    </div>
                    <div v-if='settingsMap.enableNewsShareButtons == 1' class='grid grid-cols-3 gap-2 ml-auto mr-0 invisible md:visible'>
                      <div>
                        <a :href="'https://www.facebook.com/sharer/sharer.php?u='+encodeURIComponent('https://news.thebrokersite.com/?id='+firstNewsItem.id+'&share=facebook')" target="_blank" rel="noopener" title="Share on Facebook"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="30" height="30" viewBox="0 0 256 256" xml:space="preserve">
                        <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
                          <path d="M 85.033 90 C 87.776 90 90 87.776 90 85.033 V 4.967 C 90 2.223 87.776 0 85.033 0 H 4.967 C 2.223 0 0 2.223 0 4.967 v 80.066 C 0 87.776 2.223 90 4.967 90 H 85.033 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(60,90,153); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                          <path d="M 50.916 83.204 V 48.351 h 11.699 l 1.752 -13.583 h -13.45 v -8.672 c 0 -3.933 1.092 -6.612 6.731 -6.612 l 7.193 -0.003 V 7.332 c -1.244 -0.166 -5.513 -0.535 -10.481 -0.535 c -10.37 0 -17.47 6.33 -17.47 17.954 v 10.017 H 25.16 v 13.583 h 11.729 v 34.853 H 50.916 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                        </g>
                        </svg></a>
                      </div>

                      <div>
                        <a :href="'https://www.linkedin.com/shareArticle?mini=true&url='+encodeURIComponent('https://news.thebrokersite.com/?id='+firstNewsItem.id+'&share=linkedin')" target="_blank" rel="noopener" title="Share on LinkedIn"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="30" height="30" viewBox="0 0 256 256" xml:space="preserve">
                        <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
                          <path d="M 0 6.447 C 0 2.887 2.978 0 6.651 0 h 76.698 C 87.022 0 90 2.887 90 6.447 v 77.106 C 90 87.114 87.022 90 83.349 90 H 6.651 C 2.978 90 0 87.114 0 83.553 V 6.447 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,102,153); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                          <path d="M 20.485 29.151 c 4.74 0 7.691 -3.121 7.691 -7.021 c -0.088 -3.988 -2.95 -7.022 -7.601 -7.022 c -4.65 0 -7.69 3.034 -7.69 7.022 c 0 3.9 2.95 7.021 7.512 7.021 H 20.485 L 20.485 29.151 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                          <path d="M 27.282 75.339 v -40.64 H 13.688 v 40.64 H 27.282 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                          <path d="M 34.804 75.339 h 13.594 V 52.644 c 0 -1.215 0.088 -2.428 0.447 -3.296 c 0.983 -2.427 3.219 -4.94 6.975 -4.94 c 4.919 0 6.887 3.727 6.887 9.19 v 21.741 h 13.592 V 52.037 c 0 -12.483 -6.706 -18.291 -15.65 -18.291 c -7.333 0 -10.553 4.073 -12.342 6.847 h 0.091 v -5.894 H 34.804 C 34.982 38.513 34.804 75.339 34.804 75.339 L 34.804 75.339 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                        </g>
                        </svg></a>
                      </div>

                      <div>
                        <a :href="'https://twitter.com/intent/tweet?text=' + firstNewsItem.title + '+' + encodeURIComponent('https://news.thebrokersite.com/?id='+firstNewsItem.id+'&share=x')" target="_blank" rel="noopener" title="Share on X (Twitter)"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="30" height="30" viewBox="0 0 256 256" xml:space="preserve">
                        <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
                          <polygon points="24.89,23.01 57.79,66.99 65.24,66.99 32.34,23.01 " style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform="  matrix(1 0 0 1 0 0) "/>
                          <path d="M 0 0 v 90 h 90 V 0 H 0 z M 56.032 70.504 L 41.054 50.477 L 22.516 70.504 h -4.765 L 38.925 47.63 L 17.884 19.496 h 16.217 L 47.895 37.94 l 17.072 -18.444 h 4.765 L 50.024 40.788 l 22.225 29.716 H 56.032 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                        </g>
                        </svg></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class='w-full grid grid-cols-1 md:grid-cols-2 md:gap-6'>
                <div v-for="(item, index) in restNewsItems" :key="index" class='flex flex-1 flex-col justify-between bg-white shadow overflow-hidden sm:rounded-lg py-8 px-6 mb-2'>
                  <div v-if='item.isPinned==1'>
                    <img class="float-right object-fill h-5" src="/img/pin.png" title="Pinned News Article">
                  </div>
                  <div>
                    <a href='javascript:void(0)' @click="navToNews(item.id)" class="text-lg font-bold leading-5 text-blue-700 hover:text-blue-700 mt-4 mb-2 hover:underline">{{item.title}}</a>
                    <div class='text-xs text-grey-500'>Posted by {{item.by}} on {{new Date(item.date).toLocaleDateString('en-US', {month: 'long', year: 'numeric', day: 'numeric'})}}</div>
                    <div v-html="item.bodyHtml" class='news-body mt-8 leading-5 h-96 overflow-hidden text-ellipsis'></div>
                  </div>
                  <div class='mt-auto mb-0'>
                    <a v-if='item.doReadMore' href='javascript:void(0)' @click="navToNews(item.id)" class='block mt-2 text-blue-700 hover:text-blue-700 hover:underline'>Read More</a>
                  </div>
                  <div class='grid grid-cols-2 w-full border-t mt-4 pt-4'>
                    <div>
                      <router-link :to="`/news/${getCategoryNameById(item.catId)}`" class='text-blue-700 hover:text-blue-700 truncate hover:underline'>More Related News</router-link>
                    </div>
                    <div v-if='settingsMap.enableNewsShareButtons == 1' class='grid grid-cols-3 gap-2 ml-auto mr-0 invisible md:visible'>
                      <div>
                        <a :href="'https://www.facebook.com/sharer/sharer.php?u='+encodeURIComponent('https://news.thebrokersite.com/?id='+item.id+'&share=facebook')" target="_blank" rel="noopener" title="Share on Facebook"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="30" height="30" viewBox="0 0 256 256" xml:space="preserve">
                        <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
                          <path d="M 85.033 90 C 87.776 90 90 87.776 90 85.033 V 4.967 C 90 2.223 87.776 0 85.033 0 H 4.967 C 2.223 0 0 2.223 0 4.967 v 80.066 C 0 87.776 2.223 90 4.967 90 H 85.033 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(60,90,153); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                          <path d="M 50.916 83.204 V 48.351 h 11.699 l 1.752 -13.583 h -13.45 v -8.672 c 0 -3.933 1.092 -6.612 6.731 -6.612 l 7.193 -0.003 V 7.332 c -1.244 -0.166 -5.513 -0.535 -10.481 -0.535 c -10.37 0 -17.47 6.33 -17.47 17.954 v 10.017 H 25.16 v 13.583 h 11.729 v 34.853 H 50.916 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                        </g>
                        </svg></a>
                      </div>

                      <div>
                        <a :href="'https://www.linkedin.com/shareArticle?mini=true&url='+encodeURIComponent('https://news.thebrokersite.com/?id='+item.id+'&share=linkedin')" target="_blank" rel="noopener" title="Share on LinkedIn"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="30" height="30" viewBox="0 0 256 256" xml:space="preserve">
                        <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
                          <path d="M 0 6.447 C 0 2.887 2.978 0 6.651 0 h 76.698 C 87.022 0 90 2.887 90 6.447 v 77.106 C 90 87.114 87.022 90 83.349 90 H 6.651 C 2.978 90 0 87.114 0 83.553 V 6.447 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,102,153); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                          <path d="M 20.485 29.151 c 4.74 0 7.691 -3.121 7.691 -7.021 c -0.088 -3.988 -2.95 -7.022 -7.601 -7.022 c -4.65 0 -7.69 3.034 -7.69 7.022 c 0 3.9 2.95 7.021 7.512 7.021 H 20.485 L 20.485 29.151 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                          <path d="M 27.282 75.339 v -40.64 H 13.688 v 40.64 H 27.282 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                          <path d="M 34.804 75.339 h 13.594 V 52.644 c 0 -1.215 0.088 -2.428 0.447 -3.296 c 0.983 -2.427 3.219 -4.94 6.975 -4.94 c 4.919 0 6.887 3.727 6.887 9.19 v 21.741 h 13.592 V 52.037 c 0 -12.483 -6.706 -18.291 -15.65 -18.291 c -7.333 0 -10.553 4.073 -12.342 6.847 h 0.091 v -5.894 H 34.804 C 34.982 38.513 34.804 75.339 34.804 75.339 L 34.804 75.339 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                        </g>
                        </svg></a>
                      </div>

                      <div>
                        <a :href="'https://twitter.com/intent/tweet?text=' + item.title + '+' + encodeURIComponent('https://news.thebrokersite.com/?id='+item.id+'&share=x')" target="_blank" rel="noopener" title="Share on X (Twitter)"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="30" height="30" viewBox="0 0 256 256" xml:space="preserve">
                        <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
                          <polygon points="24.89,23.01 57.79,66.99 65.24,66.99 32.34,23.01 " style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform="  matrix(1 0 0 1 0 0) "/>
                          <path d="M 0 0 v 90 h 90 V 0 H 0 z M 56.032 70.504 L 41.054 50.477 L 22.516 70.504 h -4.765 L 38.925 47.63 L 17.884 19.496 h 16.217 L 47.895 37.94 l 17.072 -18.444 h 4.765 L 50.024 40.788 l 22.225 29.716 H 56.032 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                        </g>
                        </svg></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class='w-full' v-else>
              <div class='bg-white shadow overflow-hidden sm:rounded-lg py-8 px-6 mb-4'>
                <h2 class="text-lg font-bold leading-5 text-blue-600 mt-4 mb-2">{{newsPostEnriched.title}}</h2>
                <div class='text-xs text-grey-500'>Posted by {{newsPostEnriched.by}} on {{new Date(newsPostEnriched.date).toLocaleDateString('en-US', {month: 'long', year: 'numeric', day: 'numeric'})}}</div>
                <div v-html="newsPostEnriched.bodyHtml" class='news-body mt-8 leading-5'></div>

                <div class='grid grid-cols-2 w-full border-t mt-4 pt-4'>
                  <div>
                    <router-link :to="`/news/${getCategoryNameById(newsPostEnriched.catId)}`" class='text-blue-700 hover:text-blue-700 truncate hover:underline'>More Related News</router-link>
                  </div>
                  <div v-if='settingsMap.enableNewsShareButtons == 1' class='grid grid-cols-3 gap-2 ml-auto mr-0 invisible md:visible'>
                    <div>
                      <a :href="'https://www.facebook.com/sharer/sharer.php?u='+encodeURIComponent('https://news.thebrokersite.com/?id='+newsPostEnriched.id+'&share=facebook')" target="_blank" rel="noopener" title="Share on Facebook"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="30" height="30" viewBox="0 0 256 256" xml:space="preserve">
                      <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
                        <path d="M 85.033 90 C 87.776 90 90 87.776 90 85.033 V 4.967 C 90 2.223 87.776 0 85.033 0 H 4.967 C 2.223 0 0 2.223 0 4.967 v 80.066 C 0 87.776 2.223 90 4.967 90 H 85.033 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(60,90,153); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                        <path d="M 50.916 83.204 V 48.351 h 11.699 l 1.752 -13.583 h -13.45 v -8.672 c 0 -3.933 1.092 -6.612 6.731 -6.612 l 7.193 -0.003 V 7.332 c -1.244 -0.166 -5.513 -0.535 -10.481 -0.535 c -10.37 0 -17.47 6.33 -17.47 17.954 v 10.017 H 25.16 v 13.583 h 11.729 v 34.853 H 50.916 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                      </g>
                      </svg></a>
                    </div>

                    <div>
                      <a :href="'https://www.linkedin.com/shareArticle?mini=true&url='+encodeURIComponent('https://news.thebrokersite.com/?id='+newsPostEnriched.id+'&share=linkedin')" target="_blank" rel="noopener" title="Share on LinkedIn"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="30" height="30" viewBox="0 0 256 256" xml:space="preserve">
                      <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
                        <path d="M 0 6.447 C 0 2.887 2.978 0 6.651 0 h 76.698 C 87.022 0 90 2.887 90 6.447 v 77.106 C 90 87.114 87.022 90 83.349 90 H 6.651 C 2.978 90 0 87.114 0 83.553 V 6.447 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,102,153); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                        <path d="M 20.485 29.151 c 4.74 0 7.691 -3.121 7.691 -7.021 c -0.088 -3.988 -2.95 -7.022 -7.601 -7.022 c -4.65 0 -7.69 3.034 -7.69 7.022 c 0 3.9 2.95 7.021 7.512 7.021 H 20.485 L 20.485 29.151 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                        <path d="M 27.282 75.339 v -40.64 H 13.688 v 40.64 H 27.282 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                        <path d="M 34.804 75.339 h 13.594 V 52.644 c 0 -1.215 0.088 -2.428 0.447 -3.296 c 0.983 -2.427 3.219 -4.94 6.975 -4.94 c 4.919 0 6.887 3.727 6.887 9.19 v 21.741 h 13.592 V 52.037 c 0 -12.483 -6.706 -18.291 -15.65 -18.291 c -7.333 0 -10.553 4.073 -12.342 6.847 h 0.091 v -5.894 H 34.804 C 34.982 38.513 34.804 75.339 34.804 75.339 L 34.804 75.339 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                      </g>
                      </svg></a>
                    </div>

                      <div>
                        <a :href="'https://twitter.com/intent/tweet?text=' + newsPostEnriched.title + '+' + encodeURIComponent('https://news.thebrokersite.com/?id='+newsPostEnriched.id+'&share=x')" target="_blank" rel="noopener" title="Share on X (Twitter)"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="30" height="30" viewBox="0 0 256 256" xml:space="preserve">
                        <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
                          <polygon points="24.89,23.01 57.79,66.99 65.24,66.99 32.34,23.01 " style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform="  matrix(1 0 0 1 0 0) "/>
                          <path d="M 0 0 v 90 h 90 V 0 H 0 z M 56.032 70.504 L 41.054 50.477 L 22.516 70.504 h -4.765 L 38.925 47.63 L 17.884 19.496 h 16.217 L 47.895 37.94 l 17.072 -18.444 h 4.765 L 50.024 40.788 l 22.225 29.716 H 56.032 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                        </g>
                        </svg></a>
                      </div>
                  </div>

                </div>
              </div>
            </div>

            <Pagination v-if='!isDetailPage' :itemList="newsEnriched" :payload="payload" :pageSize="pageSize"></Pagination>

          </div>
          <div class="col-span-12 invisible lg:visible lg:col-span-3 overflow-hidden px-4 mb-8">
            <h2 class="text-lg font-bold leading-5 text-red-600 mt-4 mb-4">Latest Posts</h2>

            <ul class='text-sm'>
              <li v-for="(item,index) in latestPosts" :key="index">
                <a href='javascript:void(0)' class='text-red-600 block truncate hover:underline' @click="navToNews(item.id)" :title='item.title'>{{item.title}}</a>
              </li>
            </ul>

            <h2 class="text-lg font-bold leading-5 text-red-600 mt-12 mb-4">Categories</h2>
            <ul class='text-sm'>
              <li>
                <div class='clearfix'>
                  <router-link :to="{name: 'news'}" class='float-left text-red-600  truncate hover:underline'>All</router-link>
                  <a class='float-left ml-2 rssSmall cursor-pointer' target="_blank" :href='toRSS()' title='Subscribe to the full news feed'></a>
                </div>
                <div class='clearfix'>
                  <router-link :to="`/news/${getCategoryNameById(9)}`" class='float-left text-red-600 truncate hover:underline'>Blog</router-link>
                  <a class='float-left ml-2 rssSmall cursor-pointer' target="_blank" :href='toRSS(9)' title='Subscribe to the Blog news feed'></a>
                </div>
                <div class='clearfix'>
                  <router-link :to="`/news/${getCategoryNameById(2)}`" class='float-left text-red-600 truncate hover:underline'>European Broker Meeting</router-link>
                  <a class='float-left ml-2 rssSmall cursor-pointer' target="_blank" :href='toRSS(2)' title='Subscribe to the European Broker Meeting news feed'></a>
                </div>
                <div class='clearfix'>
                  <router-link :to="`/news/${getCategoryNameById(1)}`" class='float-left text-red-600 truncate hover:underline'>Fraud Warnings</router-link>
                  <a class='float-left ml-2 rssSmall cursor-pointer' target="_blank" :href='toRSS(1)' title='Subscribe to the Fraud Warnings news feed'></a>
                </div>
                <!-- <div class='clearfix'>
                  <router-link :to="`/news/${getCategoryNameById(11)}`" class='float-left text-red-600 truncate hover:underline'>Industry Jobs</router-link>
                  <a class='float-left ml-2 rssSmall cursor-pointer' target="_blank" :href='toRSS(1)' title='Subscribe to the Industry Jobs news feed'></a>
                </div> -->
                <div class='clearfix'>
                  <router-link :to="`/news/${getCategoryNameById(3)}`" class='float-left text-red-600 truncate hover:underline'>Members</router-link>
                  <a class='float-left ml-2 rssSmall cursor-pointer' target="_blank" :href='toRSS(3)' title='Subscribe to the Members news feed'></a>
                </div>
                <div class='clearfix'>
                  <router-link :to="`/news/${getCategoryNameById(10)}`" class='float-left text-red-600 truncate hover:underline'>Stolen Equipment</router-link>
                  <a class='float-left ml-2 rssSmall cursor-pointer' target="_blank" :href='toRSS(10)' title='Subscribe to the Stolen Equipment news feed'></a>
                </div>
                <div class='clearfix'>
                  <router-link :to="`/news/${getCategoryNameById(4)}`" class='float-left text-red-600 truncate hover:underline'>The Broker Site</router-link>
                  <a class='float-left ml-2 rssSmall cursor-pointer' target="_blank" :href='toRSS(4)' title='Subscribe to the The Broker Site news feed'></a>
                </div>
              </li>
            </ul>

            <div class='mt-6 grid grid-cols-1 gap-4 sm:grid-cols-5'>
              <div class='sm:col-span-3'><input v-model='payload.query' class="form-input w-full" @keyup.enter='search'></div>
              <div class='sm:col-span-2'><button @click='search' type="button" class="btn btn-primary">Search</button></div>
            </div>

            <span class="hidden lg:block mt-16"><a href='http://eepurl.com/bG65cr' target='_blank' rel='noopener' class="border border-transparent font-medium rounded-md text-white bg-red-700 hover:bg-red-700 py-4 text-sm px-7 m-auto">Subscribe to our Newsletter</a></span>

          </div>
        </div>
      </div>
    </template>
  </base-layout>
</template>

<script>
import BaseLayout from '@/components/BaseLayout.vue'
import ListMixin from '@/components/mixins/List'
import { mapActions, mapState, mapGetters } from 'vuex';
import store from '../store'

export default {
  mixins: [ListMixin],
  name: 'News',
  components: {
    BaseLayout
  },
  // beforeRouteEnter(to, from, next) {
  //   // console.log(to.fullPath)
  //   let newsId = to.fullPath.replace('/news/', '')
  //   if (newsId=='') {
  //     next(vm => {
  //     })
  //   } else if (!isNaN(newsId)) {
  //     store.dispatch('getNewsPost', newsId).then(resp => {
  //       next(vm => {
  //         const newsDetails = vm.newsPostEnriched
  //         const descString = newsDetails.bodyHtml.substring(0, 400)
  //         const imgSource = 'https://www.thebrokersite.com/' + descString.match(/]*src="[^"]*"[^>]*>/gm).map(x => x.replace(/.*src="([^"]*)".*/, '$1'))
  //         const imgRegex = new RegExp("<img[^>]*?>", "g")
  //         const cleanDesc = descString.replace(descString.match(imgRegex), "").replace('<br>', "").replace("<strong>", "").replace("</strong>", "")
  //         // console.log(newsDetails)

  //         document.title =  `${newsDetails.title} - The Broker Site`
  //         document.querySelector('meta[name="description"]').setAttribute("content", `${cleanDesc}`)
  //         document.querySelector('meta[property="og:title"]').setAttribute("content", `${newsDetails.title} - The Broker Site`)
  //         document.querySelector('meta[property="og:description"]').setAttribute("content", `${cleanDesc}`)
  //         document.querySelector('meta[property="og:image"]').setAttribute("content", `${imgSource.replace('/../../', '')}`)
  //         document.querySelector('meta[property="og:url"]').setAttribute("content", `https://www.thebrokersite.com/news/${newsDetails.id}`)
  //       })
  //     })
  //   } else {
  //     next(vm => {
  //     })
  //   }
  // },
  
  data: () => ({
    // pageSize: 15,
    latestPosts: [],
    payload: {
      query: null, 
      catId: "",
      page: 1,
    },
    fetchActionName: "getNews",
    numResultsName: "nrNewsItems",
	categoryTitle: '',
	categories: [
		{ catId: 9, catNameShort: "Blog", catName: "Blog" },
		{ catId: 2, catNameShort: "EuropeanBrokerMeeting", catName: "European Broker Meeting" },
		{ catId: 1, catNameShort: "FraudWarnings", catName: "Fraud Warnings" },
		// { catId: 11, catNameShort: "IndustryJobs", catName: "Industry Jobs" },
		{ catId: 3, catNameShort: "Members", catName: "Members" },
		{ catId: 10, catNameShort: "StolenEquipment", catName: "Stolen Equipment" },
		{ catId: 4, catNameShort: "TheBrokerSite", catName: "The Broker Site" },
	],
  shouldUpdateNews: true,
  retrieveCount: 0,
  }),
	watch: {
		'$route.params.categoryName': 'fetchNewsByCategory',
  },
  computed: {
    ...mapState([ 'nrNewsItems', 'settingsMap']),
    ...mapGetters(['newsEnriched', 'newsPostEnriched']),
    isDetailPage(){
      return !!this.$route.params.id
    },
    firstNewsItem() {
      // console.log("First item", Object.values(this.newsEnriched)[0])
      return Object.values(this.newsEnriched)[0]
    },
    restNewsItems() {
      return Object.values(this.newsEnriched.toSpliced(0, 1))
    }
  },
  created(){
    this.getLatestPosts().then(data => {
      this.latestPosts = data.payload
    })
	this.fetchNewsByCategory()
  },
  methods: {
    ...mapActions(["getNews", 'getLatestPosts', 'getNewsPost']),
    navToNews(id){
      this.$router.push({ name: 'newsDetail', params: { id: "" + id } })
    },
    async loadDetail(id){
      await this.getNewsPost(id);
    },
      search(){
      this.payload.catId = "";
      this.getNews(this.payload);
    },
    toRSS(catId){
      const loc = window.location;
      const isDevMode = loc.port === "8080";
      const p = this.$route.fullPath;
      let q = ""
      if(catId){
        q = `?catId=${catId}`;
      }
      const url = (!isDevMode ? '/api/news/rss' : `${loc.protocol}//${loc.hostname}:3000/api/news/rss`) + q;
      return url;
    },
    getCategoryNameById(catId) {
      const category = this.categories.find(cat => cat.catId === catId)
      return category ? category.catNameShort : ''
    },
    getCategoryNameByIdLong(catId) {
      const category = this.categories.find(cat => cat.catId === catId)
      return category ? category.catName : ''
    },
    getCategoryIdByName(catNameShort) {
      const category = this.categories.find(cat => cat.catNameShort === catNameShort)
      return category ? category : null
    },
    fetchNewsByCategory() {
      const categoryName = this.$route.params.categoryName;
      const checkIfNumeric = this.isNumeric(categoryName)
      if (!checkIfNumeric) {
        if (!categoryName) {
            this.categoryName = this.getCategoryNameById(this.payload.catId)
            // console.log("No category name provided")
            this.categoryTitle = ''
            this.payload.catId = null
            this.getNews(this.payload)
            return;
        }

        const category = this.getCategoryIdByName(categoryName)

        this.categoryTitle = category ? category.catName : ''
        this.payload.catId = category ? category.catId : null

        if (this.payload.catId !== null) {
          // Hacky, added a delay because the firstNewsItem is called twice, causing the payload catId to change mid call, displaying the wrong results. With the delay, we make sure the updated payload.catId id used the second call
          setTimeout(() => {
            this.getNews(this.payload)
          }, 200)
        }
      } else {
        // load the news detail
        this.$router.push({ name: 'newsDetail', params: { id: "" + categoryName } })
      }
    },
    isNumeric(value) {
      return !isNaN(value) && !isNaN(parseFloat(value))
    },
  },
}
</script>
<style>
  .news-body img{
    margin-top: 5px;
  }
  /* em preceded by a quote */
  .news-body img + br + em {
    margin-top: -4rem;
    padding-top: 3.5rem;
  }
  /* end-quote */
  .news-body em + br + img{
    display: none;
  }
  .rssSmall {
    display: inline-block;
    width: 17px;
    height: 17px;
    background-image: url(https://www.thebrokersite.com/images/main_images.png);
    background-position: -358px -354px;
    cursor: auto;
  }
</style>
